<template>
    <div class="h5-container">
        <div class="title">{{$t('Login.Login')}}</div>
        <div class="login-wrap">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="email">
                    <el-input v-model="form.email" :placeholder="$t('Login.Email')"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="form.password" show-password :placeholder="$t('Login.Password')"></el-input>
                </el-form-item>
            </el-form>
            <el-button :loading="loading" class="submit-btn" @click="submit">{{$t('Login.Login')}}</el-button>
            <div class="other">
                <div class="item" @click="to('/h5/forget')">{{$t('Login.Forget')}}</div>
                <div class="item register" @click="to('/h5/register')">{{$t('Login.Register')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    export default {
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                rules: {
                    email: [
                        { required: true, message: this.$t('Login.EnterEmail'), trigger: 'blur' },
                        { type: 'email',  message:  this.$t('Login.EnterEmailError'), trigger: 'submit' }
                    ],
                    password: [
                        { required: true, message: this.$t('Login.EnterPassword'), trigger: 'blur' },
                        { min: 6, max: 20, message: this.$t('Login.EnterPasswordError'), trigger: 'blur' }
                    ]
                },
                loading: false
            }
        },
        computed: {
            redirect() {
                return this.$route.query.redirect || '/h5'
            }
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            },
            
            submit() {
                this.$refs.form.validate((valid) => {
                	// console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.login(this.form).then(res => {
                            this.$store.commit('setOnce',true)
                            this.$store.commit('setToken',res.data.token)
                            localStorage.setItem('isAI',res.data.ai)
                            if (this.$ws.socket) {
                                this.$ws.socket = null
                            }
                            this.to(this.redirect)
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 1.6rem 1.6rem 6rem;
        background: url(~@/assets/images/h5/login/login-bg.png) no-repeat;
        background-size: 100% auto;
        background-color: #FFFFFF;
        
        .title {
            width: 100%;
            font-size: 1.7rem;
            font-weight: bold;
            color: #000000;
            text-align: center;
        }
        
        .login-wrap {
            width: 100%;
            
            /deep/ .el-form-item {
                margin-bottom: 2.4rem;
            }
            
            /deep/ .el-input__inner {
                height: 4.2rem;
                line-height: 4.2rem;
                padding: 0 1.6rem;
                border: 1px solid #D3D3D3;
                font-size: 1.3rem;
                color: #000000;
                background-color: #F5F5F5;
            }
            
            .submit-btn {
                width: 100%;
                height: 4.8rem;
                box-sizing: border-box;
                margin: 5rem 0 1.6rem;
                padding: 0;
                border: 0;
                border-radius: 4px;
                font-size: 1.3rem;
                color: #FFFFFF;
                text-align: center;
                background-color: #5466EF;
                cursor: pointer;
            }
            
            .other {
                display: flex;
                justify-content: space-between;
                width: 100%;
                box-sizing: border-box;
                padding: 0 4px;
                
                .item {
                    max-width: 48%;
                    font-size: 1.3rem;
                    color: #798199;
                    word-wrap: break-word;
                    cursor: pointer;
                }
                
                .register {
                    color: #5466EF;
                }
            }
        }
    }
</style>
